export { userClass };
import store from "../store";
import firebase from "firebase/app";
import "firebase/messaging";
import { EventBus } from "@/main";
import SimpleCrypto from "simple-crypto-js";
import {pushConfiguration} from "@/module/vendor-config.js";

class userClass {
  constructor() {
    EventBus.$on("loginUser", (data, page) => {
      this.loginUser(data, page);
    });
    EventBus.$on("logOut", this.logoutUser);
    EventBus.$on("registerUser", (data) => {
      this.registerUser(data);
    });
  }

  loginUser(data, page) {
    let params = {};
    let logindata = {};
    let simpleCrypto = new SimpleCrypto(store.getters.SECRET_KEY);
    const affiliatecode = localStorage.getItem('promo_code_reference');

    if( ( data.app !== 'SELF' ) && affiliatecode ) params.affiliatecode = affiliatecode;

    switch (data.app) {
      case "SELF":
        params.email = data.email;
        params.password = data.password;
        logindata = {
          type: "EMAIL",
          email: data.email,
          password: simpleCrypto.encrypt(data.password)
        };
        localStorage.setItem("loginType", JSON.stringify(logindata));
        break;
      case "FB":
        params.fbtoken = data.token;
        if (data.email) {
          params.email = data.email;
          logindata = {
            type: "FB",
            token: data.token,
            email: data.email
          };
        }
        else {
          logindata = {
            type: "FB",
            token: data.token
          };
        }
        localStorage.setItem("loginType", JSON.stringify(logindata));
        break;
      case "GOOGLE":
        params.gtoken = data.token;
        logindata = {
          type: "GOOGLE",
          token: data.token
        };
        localStorage.setItem("loginType", JSON.stringify(logindata));
        break;
      case "APPLE":
        params.appletoken = data.token;
        logindata = {
          type: "APPLE",
          token: data.token
        };
        localStorage.setItem("loginType", JSON.stringify(logindata));
        break;
    }
    store.dispatch("actLogin", params).then(response => {
      if (response.error) {
        let errorData = {
          Code: response.error,
          Message: response.reason ? response.reason : response
        };
        console.log('error data', errorData);
        if (errorData.Code === 9101) {
          let obj = {};
          obj.facebookToken = params.fbtoken;
          obj.popupDisplay = true;
          obj.page="S";
          EventBus.$emit("facebookLogin", obj);
          return;
        }
        EventBus.$emit("loginError", errorData);
        return;

      } else {
        localStorage.removeItem('promo_code_reference');
        this.logindata = response;
        this.getSubscriberData(this.logindata.email, page);
      }
    })
      .catch(error => {
        // show error
        let errorData = {
          Code: error.status,
          Message: error.bodyText ? error.bodyText : error
        };
        EventBus.$emit("loginError", errorData);
      });
  }
  logoutUser() {
    store.dispatch("actLogout").then(response => {
      console.log("response ", response)
      if (response.error) {
        let errorData = {
          Code: response.error,
          Message: response.reason ? response.reason : response
        };
        EventBus.$emit("logoutError", errorData);
      } else {
        EventBus.$emit("logoutSuccess");
        // }
        localStorage.removeItem("messageToken");
        localStorage.removeItem("notificationStatus");
        localStorage.removeItem("appUser");
        localStorage.removeItem("loginType");
        localStorage.removeItem("retry");
        store.commit("CommitUserStore");
        //window.location.reload()
window.location.assign(window.location.origin);
      }
    })
      .catch(error => {
        console.log("entered error", error)
        if (error.status === 401) {
          console.log("entered")
          EventBus.$emit("logoutSuccess");
          localStorage.removeItem("messageToken");
          localStorage.removeItem("notificationStatus");
          localStorage.removeItem("appUser");
          localStorage.removeItem("loginType");
          localStorage.removeItem("retry");
          store.commit("CommitUserStore");
          //window.location.reload()
window.location.assign(window.location.origin);
        }

        let errorData = {
          Code: error.status,
          Message: error.bodyText ? error.bodyText : error
        };
        EventBus.$emit("logoutError", errorData);
        console.log("Logout Error", error);
      });
  }
  registerUser(data) {
    let simpleCrypto = new SimpleCrypto(store.getters.SECRET_KEY);
    const affiliatecode = localStorage.getItem('promo_code_reference');

    data.country = store.getters.country;
    if( affiliatecode ) data.affiliatecode = affiliatecode ;

    let logindata = {
      type: "EMAIL",
      email: data.email,
      password: simpleCrypto.encrypt(data.password)
    };
    localStorage.setItem("loginType", JSON.stringify(logindata));
    store.dispatch("actSignup", data).then(response => {
      if (response.error) {
        let errorData = {
          Code: response.error,
          Message: response.reason ? response.reason : ''
        };
        EventBus.$emit("signupError", errorData);
        return;
      }
      let params = {
        email: data.email
      };
      if (data.password) {
        params.password = data.password;
      }
      localStorage.removeItem('promo_code_reference');
      this.getRegisteredUserData(params);
    })
      .catch(error => {
        let errorData = {
          Code: error.status,
          Message: error.bodyText ? error.bodyText : ''
        };
        EventBus.$emit("signupError", errorData);
      });
  }
  getRegisteredUserData(data) {
    store.dispatch("actLogin", data).then(response => {
      if (response.error) {
        let errorData = {
          Code: response.error,
          Message: response.reason ? response.reason : ''
        };
        EventBus.$emit("signupError", errorData);
        return;
      } else {
        localStorage.setItem("appUser", JSON.stringify(response));
        store.commit("CommitUserStore");
        EventBus.$emit("signupSuccess");
        this.initFCMNotifications();
      }
    })
      .catch(error => {
        let errorData = {
          Code: error.status,
          Message: error.bodyText ? error.bodyText : ''
        };
        EventBus.$emit("signupError", errorData);
      });
  }

  getSubscriberData(email, page) {
    let data = { email };
    store.dispatch("actGetSubscriber", data).then(response => {
      let appUser = JSON.stringify({ ...this.logindata, ...response });
      localStorage.setItem("appUser", appUser);
      store.commit("CommitUserStore");
      if (page == "S") {
        EventBus.$emit("signupSuccess");
      }
      if (page == "L") {
        EventBus.$emit("loginSuccess");
      }
      this.initFCMNotifications();
    })
      .catch(error => {
        let errorData = {
          Code: error.status,
          Message: error.bodyText ? error.bodyText : ''
        };
        if (page == "S") {
          EventBus.$emit("signupError", errorData);
        }
        if (page == "L") {
          EventBus.$emit("loginError", errorData);
        }
      });
  }


  initFCMNotifications() {
    console.log("hello from fire function");

    // Retrieve Firebase Messaging object.
    let secondaryFirebase = firebase.app("secondary");
    const messaging = secondaryFirebase.messaging();

    let appConfig = JSON.parse(localStorage.getItem("appConfig"))
    let appConfigVendorId = appConfig.vendorDetails.vendorId;
    
    // Add the public key generated from the console here.
    //messaging.usePublicVapidKey(store.getters[appConfigVendorId].secondary.FCM_CERTIFICATE_KEY_2);

    
    if (navigator.serviceWorker && ('Notification' in window)) {

      navigator.serviceWorker.register("static/sw.js?"+pushConfiguration.map(attr=>attr.join("=")).join("&")).then((registration) => {

        //messaging.useServiceWorker(registration);

        // messaging
        //   .requestPermission()
          Notification.requestPermission().then((permission) => {
            console.log(permission);
            if(permission!=='granted')return;
            messaging
              .getToken({
                        vapidKey: store.getters.FirebaseConfig.certificateKey,
                        serviceWorkerRegistration:registration
                      })
              .then((token) => {
                localStorage.setItem("messageToken", token);
                // notification
                let notificationStatus = localStorage.getItem(
                  "notificationStatus"
                );
                if (
                  notificationStatus !== null &&
                  JSON.parse(notificationStatus)
                ) {
                  store.dispatch("subscribeVendorAndSubscriberTopics");
                } else {
                  if (notificationStatus === null || JSON.parse(notificationStatus) === false) {
                    localStorage.setItem(
                      "notificationStatus",
                      JSON.stringify(true)
                    );
                    store.commit("commitNotificationStatus", true);
                    store.dispatch("subscribeVendorAndSubscriberTopics");
                  }
                }
              })
              .catch((err) => {
                console.log("[err]", err);
              });
          })
          .catch((err) => {
            localStorage.setItem(
              "notificationStatus",
              JSON.stringify(false)
            );
            store.commit("commitNotificationStatus", false);

            console.log("[err]", err);
          });

        // messaging.onMessage((payload) => {
        navigator.serviceWorker.addEventListener("message", payload => {

          console.log("[onMessage payload]", payload);
          //if (!store.getters.AppUser) return;
          let payloadData = payload.data.firebaseMessagingData.data;

          let data = {
            data: payloadData,
            subscriberid: store.getters.subscriberId,
            read: false,
            timestamp: new Date(),
            // timestamp: Math.floor(new Date().getTime() / 1000)
          };
          let fcm_notifications = localStorage.getItem("fcm_notifications");
          let notifications;
          if (fcm_notifications) {
            notifications = JSON.parse(fcm_notifications);
            notifications = [...notifications, data];
          } else {
            notifications = [data];
          }
          notifications = notifications.slice().reverse();

          localStorage.setItem(
            "fcm_notifications",
            JSON.stringify(notifications)
          );
          store.commit("commitFcmNotifications", true);

          if (Notification.permission === "granted") {
            let data = payload.data.firebaseMessagingData.data;
            var title = "vLite Notification";
            let body = "New message from vLite";
            let image = null;
            let tag = null;
            if (payload.data) {
              title = data.title;
              body = data.message;
              image = data.image_url;
              tag = data.contentid ? `detail-card/${data.contentid}` : '';
            }
            var options = {
              body,
              image,
              tag
            };
            registration.showNotification(title, options);
          }
        });
      });
    }
    ////
  }
}
